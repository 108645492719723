// Flex rows
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-x {
    display: flex;
    align-items: center;
}

@mixin flex-center-y {
    display: flex;
    justify-content: center;
}

// Flex columns
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin flex-column-center-x {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flex-column-center-y {
    display: flex;
    flex-direction: column;
    align-items: center;
}

// OTHERS
@mixin backgroundBlob {
    position: absolute;
    pointer-events: none;
    width: 140vw;
    height: 140vw;
    left: -20vw;
    transition: 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);

    color: $black;
}

@mixin noBlend {
    position: relative;
    z-index: 500;
}