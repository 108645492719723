@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
// Nunito
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');



$size-text: 16px;
$line-height: 1.5;

$h4: $size-text * $line-height;
$h3: $h4 * $line-height;
$h2: $h3 * $line-height;
$h1: $h2 * $line-height;

body {
  font-family: 'Nunito', sans-serif;
  font-size: $size-text;
  line-height: $line-height;
  font-weight: 900!important;
  color: $black;
}

h1 {
  font-size: $h1;
  font-weight: 900!important;
}

h2 {
  font-weight: 900!important;
  font-size: $h2;
}

h3 {
  font-weight: 900!important;
  font-size: $h3;
}

h4 {
  font-weight: 900!important;
  font-size: $h4;
}

p {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

a {
  font-weight: 600;
}

label {
  font-weight: 700;
}