.useSvg {display: none;}
html, body {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $white;
}

body::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1500;
    mix-blend-mode: color;
    pointer-events: none;
    background: linear-gradient(45deg, var(--c-1) 20%, var(--c-2) 100%);
}

header, section {
    max-width: 500px;
}

.btn {
    padding: 1rem;
    border: 3px solid $black;
    background-color: transparent;
    border-radius: $rounded;
    transition: $t-fast;

    text-align: center;

    white-space: nowrap;

    &-dark {
        border: none;
        background-color: $black;
        color: $white;
    }

    &-white {
        border: none;
    }

    &-bottom {
        position: absolute;
        left: 0;
        margin: 0 2rem;
        bottom: 2rem;
        width: calc(100% - 4rem);
        box-sizing: border-box;
    }

    &-disabled {
        position: relative;
        opacity: .5;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            background: repeating-linear-gradient( -45deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5) 5px, transparent 5px, transparent 25px );
        }
    }
}

.header {
    color: $white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &-title {
        @include noBlend;

        font-size: $h3;
        font-weight: bold;
    }
}

.menu {
    margin-top: 12.5vh;
    &-title {
        text-align: center;
    }
    &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 1rem;
        &-el {
            &:nth-child(1) {
                grid-row: 1 / 2;
                grid-column: 1 / 4;
            }

            &:nth-child(2) {
                grid-row: 2 / 3;
                grid-column: 1 / 2;
            }

            &:nth-child(3) {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
            }

            &:nth-child(4) {
                grid-row: 2 / 3;
                grid-column: 3 / 4;
            }

            &:nth-child(5) {
                grid-row: 3 / 4;
                grid-column: 1 / 4;
            }

            .btn {
                width: 100%;
            }
        }
    }
}


.custom {
    .form {
        @include noBlend;
        label {color: $white;}
        &-feild {
            margin-top: 0.5rem;
            display: flex;
            input {
                background-color: $white;
                text-align: left;
                width: 100%;
                
                &::placeholder {
                    opacity: .5;
                }
            }
        }
        .btn-submit {
            @include flex-center;
            margin-left: 0.5rem;
            background-color: $white;

            &-svg {
                opacity: .5;
                width: 1rem;
                height: 1rem;
            }
        }
    }
    

    .choiceList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        padding: 2rem 0;
        &-el {
            background-color: $white;
            z-index: 1000;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            &:last-child {
                margin-right: 0;
            }
            
            &.addAnim {
                animation: addAnim 0.5s;
            }
            
            &.deleteAnim {
                animation: deleteAnim 0.3s;
            }
        }

    }

    &-nav {
        position: absolute;
        left: 0;
        margin: 0 2rem;
        bottom: 2rem;
        width: calc(100% - 4rem);
        box-sizing: border-box;
        &-go {
            width: 100%;
        }

        &-return {
            padding: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.result {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50vh;
    margin-top: 10vh;

    &-container {
        font-size: $h3;
        position: relative;
        color: $black;
        z-index: 1400;
        
        opacity: 0;
        transition: $t-slow;
    }

    .blob-result {
        @include backgroundBlob;
        z-index: 1300;
        display: none;
        width: 80vw;
        height: 50vh;
        top: 25vh;
        left: 10vw;
        color: $white;
        transform: rotate(250deg) translateY(-10px) translateX(-20px);
        animation: blobResult 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        animation-delay: 0.3s;
    }
}

// BLOBS

.blob {
    &-top {
        @include backgroundBlob;
        
        bottom: 75vh;
        transform: rotate(10deg);
    }
    &-bottom {
        @include backgroundBlob;
    
        top: 80vh;
        transform: rotate(45deg);
    }
}

section {
    padding: 0 2rem;
    display: none;
    animation: sectionAppear 0.3s;
}

body[data-page="menu"] {.section.menu {display: block;}}

body[data-page="custom"] {
    .section.custom {display: block;}
    .blob {
        &-top {
            transform: rotate(15deg) scale(1.5) translateY(20px);
        }
        &-bottom {
            top: 100vh;
        }
    }
    
}

body[data-page="result"] {
    .section.result {display: flex;}
    .result-container {
        opacity: 1;
        transition: $t-fast;
        transition-delay: 0.5s;
    }
    .blob {
        &-top {
            transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);;
            transform: rotate(90deg) scale(1.5) translateY(-5vh) translateX(30vh);
        }
        &-bottom {
            top: 100vh;
        }

        &-result {
            display: block;
        }
    }
}
