body {
  margin: 0;
}

img {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input {
  font-family: inherit;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  border: none;
  &:focus-visible {
    outline: none;
    color: inherit;
  }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}