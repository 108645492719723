@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 900 !important;
  color: #b3b3b3;
}

h1 {
  font-size: 81px;
  font-weight: 900 !important;
}

h2 {
  font-weight: 900 !important;
  font-size: 54px;
}

h3 {
  font-weight: 900 !important;
  font-size: 36px;
}

h4 {
  font-weight: 900 !important;
  font-size: 24px;
}

p {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

a {
  font-weight: 600;
}

label {
  font-weight: 700;
}

.useSvg {
  display: none;
}

html, body {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
}

body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  mix-blend-mode: color;
  pointer-events: none;
  background: linear-gradient(45deg, var(--c-1) 20%, var(--c-2) 100%);
}

header, section {
  max-width: 500px;
}

.btn {
  padding: 1rem;
  border: 3px solid #b3b3b3;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  text-align: center;
  white-space: nowrap;
}
.btn-dark {
  border: none;
  background-color: #b3b3b3;
  color: #fff;
}
.btn-white {
  border: none;
}
.btn-bottom {
  position: absolute;
  left: 0;
  margin: 0 2rem;
  bottom: 2rem;
  width: calc(100% - 4rem);
  box-sizing: border-box;
}
.btn-disabled {
  position: relative;
  opacity: 0.5;
}
.btn-disabled::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 5px, transparent 5px, transparent 25px);
}

.header {
  color: #fff;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.header-title {
  position: relative;
  z-index: 500;
  font-size: 36px;
  font-weight: bold;
}

.menu {
  margin-top: 12.5vh;
}
.menu-title {
  text-align: center;
}
.menu-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
}
.menu-grid-el:nth-child(1) {
  grid-row: 1/2;
  grid-column: 1/4;
}
.menu-grid-el:nth-child(2) {
  grid-row: 2/3;
  grid-column: 1/2;
}
.menu-grid-el:nth-child(3) {
  grid-row: 2/3;
  grid-column: 2/3;
}
.menu-grid-el:nth-child(4) {
  grid-row: 2/3;
  grid-column: 3/4;
}
.menu-grid-el:nth-child(5) {
  grid-row: 3/4;
  grid-column: 1/4;
}
.menu-grid-el .btn {
  width: 100%;
}

.custom .form {
  position: relative;
  z-index: 500;
}
.custom .form label {
  color: #fff;
}
.custom .form-feild {
  margin-top: 0.5rem;
  display: flex;
}
.custom .form-feild input {
  background-color: #fff;
  text-align: left;
  width: 100%;
}
.custom .form-feild input::placeholder {
  opacity: 0.5;
}
.custom .form .btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  background-color: #fff;
}
.custom .form .btn-submit-svg {
  opacity: 0.5;
  width: 1rem;
  height: 1rem;
}
.custom .choiceList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
.custom .choiceList-el {
  background-color: #fff;
  z-index: 1000;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.custom .choiceList-el:last-child {
  margin-right: 0;
}
.custom .choiceList-el.addAnim {
  animation: addAnim 0.5s;
}
.custom .choiceList-el.deleteAnim {
  animation: deleteAnim 0.3s;
}
.custom-nav {
  position: absolute;
  left: 0;
  margin: 0 2rem;
  bottom: 2rem;
  width: calc(100% - 4rem);
  box-sizing: border-box;
}
.custom-nav-go {
  width: 100%;
}
.custom-nav-return {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.result {
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;
  margin-top: 10vh;
}
.result-container {
  font-size: 36px;
  position: relative;
  color: #b3b3b3;
  z-index: 1400;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}
.result .blob-result {
  position: absolute;
  pointer-events: none;
  width: 140vw;
  height: 140vw;
  left: -20vw;
  transition: 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #b3b3b3;
  z-index: 1300;
  display: none;
  width: 80vw;
  height: 50vh;
  top: 25vh;
  left: 10vw;
  color: #fff;
  transform: rotate(250deg) translateY(-10px) translateX(-20px);
  animation: blobResult 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  animation-delay: 0.3s;
}

.blob-top {
  position: absolute;
  pointer-events: none;
  width: 140vw;
  height: 140vw;
  left: -20vw;
  transition: 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #b3b3b3;
  bottom: 75vh;
  transform: rotate(10deg);
}
.blob-bottom {
  position: absolute;
  pointer-events: none;
  width: 140vw;
  height: 140vw;
  left: -20vw;
  transition: 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #b3b3b3;
  top: 80vh;
  transform: rotate(45deg);
}

section {
  padding: 0 2rem;
  display: none;
  animation: sectionAppear 0.3s;
}

body[data-page=menu] .section.menu {
  display: block;
}

body[data-page=custom] .section.custom {
  display: block;
}
body[data-page=custom] .blob-top {
  transform: rotate(15deg) scale(1.5) translateY(20px);
}
body[data-page=custom] .blob-bottom {
  top: 100vh;
}

body[data-page=result] .section.result {
  display: flex;
}
body[data-page=result] .result-container {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  transition-delay: 0.5s;
}
body[data-page=result] .blob-top {
  transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: rotate(90deg) scale(1.5) translateY(-5vh) translateX(30vh);
}
body[data-page=result] .blob-bottom {
  top: 100vh;
}
body[data-page=result] .blob-result {
  display: block;
}

body {
  margin: 0;
}

img {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input {
  font-family: inherit;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  border: none;
}
button:focus-visible, input:focus-visible {
  outline: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@keyframes deleteAnim {
  from {
    opacity: 1;
    transform: scale(1) rotate(0) translateY(0);
  }
  to {
    opacity: 0;
    transform: scale(0.3) rotate(200deg) translateY(20px);
  }
}
@keyframes addAnim {
  from {
    opacity: 0;
    transform: translateY(-50px) rotate(-20deg) scale(0.6);
  }
  to {
    opacity: 1;
    transform: translateY(0px) rotate(0deg) scale(1);
  }
}
@keyframes sectionAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes blobResult {
  from {
    opacity: 0;
    transform: skew(20deg) rotate(200deg);
  }
  to {
    opacity: 1;
    transform: skew(0deg) rotate(250deg) translateY(-10px) translateX(-20px);
  }
}
@media (min-width: 500px) {
  html {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body {
    position: relative;
    height: 700px;
    max-width: 400px;
    overflow: hidden;
    border-radius: 10px;
  }

  .section.result {
    height: 300px;
  }

  .blob {
    width: 550px;
    height: 550px;
    left: -50px;
  }
  .blob-top {
    bottom: 500px;
  }
  .blob-bottom {
    top: 520px;
  }

  body[data-page=menu] .section.menu {
    display: block;
  }

  body[data-page=result] .blob-top {
    transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    transform: rotate(90deg) scale(1.5) translateY(-5vh) translateX(20vh);
  }
  body[data-page=result] .blob-bottom {
    top: 100vh;
  }
  body[data-page=result] .blob-result {
    width: 380px;
    height: 380px;
    top: 150px;
    left: 5px;
  }
}