// Tablet
@media (min-width: 500px) {
    html {
        @include flex-center;
    }
    body {
        position: relative;
        height: 700px;        
        max-width: 400px;
        overflow: hidden;
        border-radius: $rounded2;
    }

    
    .section.result {
        height: 300px;
    }

    .blob {
        width: 550px;
        height: 550px;
        left: -50px;
        &-top {
            bottom: 500px;
        }

        &-bottom  {
            top: 520px;
        }
    }


    body[data-page="menu"] {.section.menu {display: block;}}

    body[data-page="result"] {
        .blob {
            &-top {
                transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);;
                transform: rotate(90deg) scale(1.5) translateY(-5vh) translateX(20vh);
            }
            &-bottom {
                top: 100vh;
            }

            &-result {
                width: 380px;
                height: 380px;
                top: 150px;
                left: 5px;
            }
        }
    }
}

// desktop
@media(min-width: 900px) {

}