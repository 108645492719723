@keyframes deleteAnim {
    from {opacity: 1; transform: scale(1) rotate(0) translateY(0);}
    to {opacity: 0; transform: scale(0.3) rotate(200deg) translateY(20px);}
}

@keyframes addAnim {
    from {opacity: 0; transform: translateY(-50px) rotate(-20deg) scale(0.6);}
    to {opacity: 1; transform: translateY(0px) rotate(0deg) scale(1);}
}

@keyframes sectionAppear {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes blobResult {
    from {opacity: 0; transform: skew(20deg) rotate(200deg);}
    to {opacity: 1; transform: skew(0deg) rotate(250deg) translateY(-10px) translateX(-20px);}
}