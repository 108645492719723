$white: #fff;
$black: hsl(0, 0%, 70%);

$prim: var(--ux-c);
$sec: var(--blob-c);

$c-text: #373737;

$t-slow: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
$t-fast: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
$t-superfast: all 0.1s cubic-bezier(0.33, 1, 0.68, 1);
$t-tfast: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
$t-tslow: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);

// border-radius
$round: 1000px;
$rounded: 5px;
$rounded2: 10px;

// shadows
$shadow: 0px 0px 25px -10px #00000038;  
$light-shadow: 0px 0px 25px -15px #00000038;

// z-index
$background: -1000;

// layout - to complete